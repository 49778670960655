<template>
  <div style="padding: 10px">
    <div style="background: #fff; border-radius: 8px; padding: 20px">
      <div class="query-c">
         <Form  :label-width="80"  inline>
          <FormItem label="用户名">
         <Input  v-model="sevo.userName" clearable placeholder="请输入用户名" style="width: 150px" />
        </FormItem>


        <FormItem label="账号">
            <Input  v-model="sevo.account" clearable placeholder="请输入账号" style="width: 150px" />
       </FormItem>
         <FormItem label="电话">
            <Input  v-model="sevo.phone" clearable placeholder="请输入电话" style="width: 150px" />
       </FormItem>
        <Button type="primary" class="search-bt" @click="findAlluser"  icon="ios-search">查询</Button>
        <Button type="primary"  class="add" icon="md-add" @click="addModal=true">添加</Button>
       </Form>

      </div>
      <br />
      <Table
        :max-height="maxHeight"
        border
        stripe
        :columns="columns"
        :data="userList"
      ></Table>
      <br />
      <Page
            :total="total"
            @on-change="changePage"
            @on-page-size-change="changePageNum"
            show-sizer
            show-elevator />
    </div>
     <Modal
        v-model="addModal"
        title="新增"
        :footer-hide="true"
         >
        <Form ref="savo"  :model="savo" :rules="rulesavo" :label-width="80">
        <FormItem label="用户名" prop="userName">
            <Input type="text" v-model="savo.userName"></Input>
        </FormItem>
        <FormItem label="账号" prop="account">
            <Input type="text" v-model="savo.account"></Input>
        </FormItem>
        <FormItem label="电话" prop="phone">
            <Input type="text" v-model="savo.phone"></Input>
        </FormItem>
        <FormItem label="员工编号" prop="userNo">
            <Input type="text" v-model="savo.userNo"></Input>
        </FormItem>
        <FormItem>
            <Button type="primary" @click="add('savo')">保存</Button>
            <Button @click="resetSave('savo')" style="margin-left: 8px">重置</Button>
        </FormItem>
    </Form>

    </Modal>


      <!--修改-->
        <Modal
        v-model="updateModal"
        title="修改"
        :footer-hide="true"
         >
         <Form ref="upvo"  :model="upvo" :rules="rulesavo" :label-width="80">
        <FormItem label="用户名" prop="userName">
            <Input type="text" v-model="upvo.userName"></Input>
        </FormItem>
        <FormItem label="账号" prop="account">
            <Input type="text" v-model="upvo.account"></Input>
        </FormItem>
        <FormItem label="电话" prop="phone">
            <Input type="text" v-model="upvo.phone"></Input>
        </FormItem>
        <FormItem label="密码" prop="passWord">
            <Input type="password" v-model="upvo.passWord"></Input>
        </FormItem>
        <FormItem label="角色" prop="roleName">
             <Select
                v-model="upvo.rid"
                filterable
                clearable
                placeholder="角色"
                 >
                <Option v-for="(option, index) in roleList" :value="option.rid"     :key="index">{{option.roleName}}</Option>
            </Select>
        </FormItem>
        <FormItem>
            <Button type="primary" @click="updateUserInfo('upvo')">保存</Button>
            <Button @click="resetSave('upvo')" style="margin-left: 8px">重置</Button>
        </FormItem>
    </Form>
    </Modal>

      <!--删除-->
    <Modal v-model="delModal" width="360">
             <p slot="header" style="color:#f60;text-align:center">
            <Icon type="ios-information-circle"></Icon>
            <span>删除</span>
        </p>
        <div style="text-align:center">

            <p>确定要删除?</p>
        </div>
        <div slot="footer">
            <Button type="error" size="large" long :loading="delLoading" @click="del">删除</Button>
        </div>
    </Modal>
  </div>
</template>

<script>
import { findUserList, addUser, delUer, findAllRole, updateUser } from '@/api'

export default {
  name: 'userpage',
  data() {
    return {
      maxHeight: 800,
      addModal: false,
      updateModal: false,
      delModal: false,
      delLoading: false,
      pageIndex: 1,
      pageSize: 10,
      pageNum: 10,
      total: 100,
      sevo: {}, // 查询
      upvo: {}, // 修改
      savo: {}, // 保存
      deluid: 0,
      rulesavo: {
        userName: [
              { required: true, message: '用户名不能为空', trigger: 'blur' },
        ],
         account: [
              { required: true, message: '账号不能为空', trigger: 'blur' },
        ],
         phone: [
              { required: false, message: '宽度不能为空', trigger: 'blur' },
        ],
         roleId: [
              { required: false, message: '价格不能为空', trigger: 'blur' },
        ],
      },
      columns: [
           {
              type: 'index',
              width: 60,
              align: 'center',
            },
        {
          title: '用户名',
          key: 'userName',
          resizable: true,
        },
        {
          title: '账号',
          key: 'account',
          resizable: true,
        },
        {
          title: '电话',
          key: 'phone',
          resizable: true,
        },
         {
          title: '员工编号',
          key: 'userNo',
          resizable: true,
        },
         {
          title: '角色',
          key: 'role',
          resizable: true,
          render: (h, params) => {
            console.log(params)
            if (params.row.role === null) {
              return h('div', '未分配')
            }
              return h('div', params.row.role.roleName)
            },
        },

        {
          title: '操作',
          key: 'action',
          width: 150,
          align: 'center',
          render: (h, params) => h('div', [
              h(
                'Button',
                {
                  props: {
                    type: 'primary',
                    size: 'small',
                  },
                  style: {
                    marginRight: '5px',
                  },
                  on: {
                    click: () => {
                      this.update(params)
                    },
                  },
                },
                '修改'
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'error',
                    size: 'small',
                  },
                  on: {
                    click: () => {
                      this.remove(params.row)
                    },
                  },
                },
                '删除'
              ),
            ]),
        },
      ],
      userList: [],
      roleList: [],
      roleName: '',
      roleId: '',
      role: {},
    }
  },
  mounted() {
    // 设置表格高度
    const t = this
    t.maxHeight = window.innerHeight - 240
    this.findAlluser()
    this.findRoleList()
    // this.findCzList();
    // this.findThickList();
    // this.findwideList();
  },
created() {

  },
methods: {
    findAlluser() {
      let t = this
      let url = ''
      url += '&pageIndex=' + t.pageIndex
      url += '&pageSize=' + t.pageSize
      if (t.sevo.userName) {
        url += '&userName=' + t.sevo.userName
      }
      if (t.sevo.phone) {
        url += '&phone=' + t.sevo.phone
      }
      if (t.sevo.account) {
        url += '&account=' + t.sevo.account
      }
        findUserList(url).then((res) => {
        t.userList = res.data.list
        t.total = res.data.count
      })
    },
    findRoleList() {
      findAllRole().then((res) => {
        this.roleList = res.data
      })
    },
    updateUserInfo(name) {
      this.$refs[name].validate((valid) => {
                if (valid) {
                    let sa = this.upvo
                    console.log(sa)
                    updateUser(sa).then(() => {
                    this.upvo = {}
                    this.$Message.success('操作成功')
                    this.findAlluser()
                    this.updateModal = false
                    })
                }
        })
    },
    update(params) {
        let t = this
        t.upvo = params.row
        if (params.row.role) {
            t.upvo.rid = params.row.role.rid
            t.roleName = params.row.role.roleName
        }
        t.updateModal = true
    },
    cancelUpdateModal() {
        let t = this
        t.updateModal = false
    },
    add(name) {
      this.$refs[name].validate((valid) => {
                if (valid) {
                    let sa = this.savo
                    addUser(sa).then(() => {
                    this.savo = {}
                    this.$Message.success('操作成功')
                    this.findAlluser()
                    this.addModal = false
                    })
                }
        })
    },
    resetSave(name) {
      this.$refs[name].resetFields()
    },
    cancelAddModal() {
        this.savo = {}
    },
    remove(params) {
        let t = this
        t.deluid = params.uid
        t.delModal = true
    },
del() {
        let t = this
        delUer(t.deluid).then(() => {
            this.$Message.success('操作成功')
        })
        t.delModal = false
        t.findAlluser()
    },
changePage(v) {
      let t = this
      t.pageIndex = v
      t.findAlluser()
    },
changePageNum(v) {
      let t = this
      t.pageSize = v
      t.findAlluser()
    },
getRid(v) {
      console.log(v)
    },
  },
}
</script>

<style scoped>
.add{
    position: absolute;
    right: 40px;
}
.search-bt{
      position: absolute;
    right: 140px;
}
.query-c{
  height: 30px;
}
</style>